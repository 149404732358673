import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PlaceIcon from "@mui/icons-material/Place";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { isLoggedin } from "../helpers/utils";
import { apiRequest } from "../helpers/apiRequest";
import moment from "moment";
import Loader from "../components/Loader";

const Onboard = ({ setLayout }) => {
  const [onboardedUsers, setOnboardedUsers] = useState([]);
  const [isLoading, setLoading] = useState(Boolean);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await apiRequest("/rxone/list/reg/users", "get");
      const users = response.users;
      setOnboardedUsers(users);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (!isLoggedin) {
        navigate("/login");
      }
      console.log(error);
    }
  };

  useEffect(() => {
    setLayout(true);

    if (!isLoggedin()) {
      console.log(`here`);
      navigate("/login");
    }
    setLoading(true);
    fetchData();
  }, []);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="m-3 mb-20">
          <p className="text-xs mt-2 mb-4">
            Click on User Card to see the current status of user
          </p>

          {onboardedUsers && onboardedUsers.length
            ? onboardedUsers.map((user) => {
                return (
                  <Link to={`user/${user.user_id}`} key={user.user_id}>
                    <Card className="m-2" key={user.user_id}>
                      <div className="flex">
                        <div className="flex flex-col text-center justify-center items-center">
                          <PlaceIcon />
                          <div className="min-w-[100px]">{user.category}</div>
                        </div>
                        <div className="flex flex-col text-sm">
                          <p className="text-base">{user.full_name}</p>
                          <div className="flex">
                            <LocalPhoneIcon />
                            <p>{user.phone}</p>
                          </div>
                          <div className="flex">
                            <EmailIcon />
                            <p>{user.email}</p>
                          </div>
                          <p>
                            Registered On:
                            {moment(user.registered_on).format("Do MMM YYYY")}
                          </p>
                          <p>
                            Email Confirmation:
                            <span
                              className={`${
                                user.email_confirmation === "Completed"
                                  ? "text-lime-500"
                                  : "text-orange-500"
                              }`}
                            >
                              {user.email_confirmation}
                            </span>
                          </p>
                        </div>
                      </div>
                    </Card>
                  </Link>
                );
              })
            : "No Onboarded Users"}
          <div>
            <Link to="add">
              <Button className="w-full my-5 bg-[#4472c4]" variant="contained">
                Register New +
              </Button>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default Onboard;
