import React, { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputField from "../components/InputField";
import AppButton from "../components/AppButton";
import { apiRequest } from "../helpers/apiRequest";
import OverlayLoader from "../components/OverlayLoader";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import Button from "@mui/material/Button";
import FormData from "form-data";
import { uploadFacilityDocument } from "../apis/facility";
const formdata = new FormData();
const steps = ["Facility Details", "Upload Proofs"];
const validationSchema = Yup.object({
  hosp_name: Yup.string()
    .min(2)
    .max(50)
    .required("Please enter your Facility Name"),
  short_name: Yup.string()
    .min(2)
    .max(50)
    .required("Please enter your Facility Short Name"),
  hosp_registration_no: Yup.string()
    .min(2)
    .max(50)
    .required("Please enter your Registration No"),
  hosp_accreditation_by: Yup.string()
    .min(2)
    .max(50)
    .required("Please enter your Accreditation By"),
  beds_count: Yup.number().required("Please enter your Beds Count"),
  email: Yup.string().email().required("Please enter your Contact Email"),
  phone1: Yup.string()
    .min(10)
    .max(10)
    .required("Please enter your primary Contact No"),
  phone2: Yup.string()
    .min(10)
    .max(10)
    .required("Please enter your alternate Contact No"),
  address: Yup.string().min(2).max(50).required("Please enter your Address"),
  city: Yup.string().min(2).max(50).required("Please enter your City"),
});

export default function RegisterFacility({ setLayout }) {
  const { userid } = useParams();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [isLoading, setLoading] = React.useState(false);
  const [hos_id, set_hos_id] = React.useState(null);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const initialValues = {
    hosp_name: "",
    short_name: "",
    hosp_registration_no: "",
    hosp_accreditation_by: "",
    beds_count: "",
    email: "",
    phone1: "",
    phone2: "",
    address: "",
    city: "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (values) => {
        values.phone1 = "+91" + values.phone1;
        values.phone2 = "+91" + values.phone2;
        setLoading(true);
        try {
          const response = await apiRequest(
            `/rxone/register/facility/${process.env.MODE}/${userid}`,
            "post",
            values
          );

          if (response && response.hos_id) set_hos_id(response.hos_id);
          else throw new Error("Something went wrong!");
          handleNext();
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      },
    });

  useEffect(() => {
    setLayout(false);
  }, [setLayout]);
  return (
    <>
      {isLoading && <OverlayLoader />}
      <div
        className="h-16 flex items-center border-b-2 border-[#a5a5a5]"
        onClick={() => {
          if (activeStep > 0) handleBack();
          else navigate("/facilities");
        }}
      >
        <div className="pl-5 flex items-center">
          <ArrowBackIcon />
          <p className="pl-1 ">{" Back"}</p>
        </div>
      </div>

      <div className="p-5">
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{
            "& .MuiStepConnector-line": {
              display: "none",
            },
          }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel
                sx={{
                  "& .MuiStepLabel-label": {
                    fontSize: "10px",
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 ? (
          <>
            <form onSubmit={handleSubmit}>
              <InputField
                id={"hosp_name"}
                name={"hosp_name"}
                type={"text"}
                placeholder={"Facility Name"}
                value={values.hosp_name}
                error={errors.hosp_name && touched.hosp_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <InputField
                id={"short_name"}
                name={"short_name"}
                type={"text"}
                placeholder={"Facility Short Name"}
                value={values.short_name}
                error={errors.short_name && touched.short_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <InputField
                id={"hosp_registration_no"}
                name={"hosp_registration_no"}
                type={"text"}
                placeholder={"Registration No."}
                value={values.hosp_registration_no}
                error={
                  errors.hosp_registration_no && touched.hosp_registration_no
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <InputField
                id={"hosp_accreditation_by"}
                name={"hosp_accreditation_by"}
                type={"text"}
                placeholder={"Accreditation By"}
                value={values.hosp_accreditation_by}
                error={
                  errors.hosp_accreditation_by && touched.hosp_accreditation_by
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <InputField
                id={"beds_count"}
                name={"beds_count"}
                type={"text"}
                placeholder={"Beds Count"}
                value={values.beds_count}
                error={errors.beds_count && touched.beds_count}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <InputField
                id={"email"}
                name={"email"}
                type={"email"}
                placeholder={"Contact Email"}
                value={values.email}
                error={errors.email && touched.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <InputField
                id={"phone1"}
                name={"phone1"}
                type={"mobileno"}
                placeholder={"Contact No. (1)"}
                value={values.phone1}
                error={errors.phone1 && touched.phone1}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <InputField
                id={"phone2"}
                name={"phone2"}
                type={"mobileno"}
                placeholder={"Contact No. (2)"}
                value={values.phone2}
                error={errors.phone2 && touched.phone2}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <InputField
                id={"address"}
                name={"address"}
                type={"text"}
                placeholder={"Address"}
                value={values.address}
                error={errors.address && touched.address}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <InputField
                id={"city"}
                name={"city"}
                type={"text"}
                placeholder={"City"}
                value={values.city}
                error={errors.city && touched.city}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <AppButton buttonText={"Save & Next"} />
            </form>
          </>
        ) : (
          <Step2Form hos_id={hos_id} />
        )}
      </div>
    </>
  );
}

const Step2Form = ({ hos_id }) => {
  const navigate = useNavigate();
  const fileRef = useRef(null);
  const logoRef = useRef(null);
  const [isLoading, setLoading] = React.useState(false);
  const [fileRegName, setFileRegName] = React.useState("");
  const [fileLogoName, setFileLogoName] = React.useState("");
  const initialValues = {
    file_registration_certificate: null,
    file_facility_logo: null,
  };

  const validationSchema = Yup.object({
    file_registration_certificate: Yup.mixed()
      .nullable()
      .required()
      .test("FILE_SIZE", "Upload File is too large", (value) => {
        return !value || (value && value.size <= 1024 * 1024);
      }),
    file_facility_logo: Yup.mixed()
      .nullable()
      .required()
      .test("FILE_SIZE", "Upload File is too large", (value) => {
        return !value || (value && value.size <= 1024 * 1024);
      }),
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      formdata.append(
        "registration_certificate",
        values.file_registration_certificate,
        values.file_registration_certificate.name
      );
      formdata.append(
        "brand_logo",
        values.file_facility_logo,
        values.file_facility_logo.name
      );
      setLoading(true);
      try {
        await uploadFacilityDocument(hos_id, formdata);
        navigate(`/facilities`);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
  });
  return (
    <>
      {isLoading && <OverlayLoader />}
      <form onSubmit={handleSubmit}>
        <div className="my-2 border border-[#a5a5a5] rounded">
          <Button
            className="bg-[#fff] w-full text-[#a5a5a5] justify-between h-14 shadow-none"
            variant="contained"
            component="label"
            endIcon={<CameraAltIcon />}
            onClick={() => {
              fileRef.current.click();
            }}
            sx={{
              "& .MuiButton-endIcon": {
                color: "black",
              },
              "& .MuiButton-endIcon>*:nth-of-type(1)": {
                fontSize: "30px",
              },
            }}
          >
            {fileRegName ? (
              <p className="text-[#1976d2]">{fileRegName} </p>
            ) : (
              "Upload Registration Certificate"
            )}
          </Button>
          <input
            id="file_registration_certificate"
            name="file_registration_certificate"
            onChange={(event) => {
              setFieldValue(
                "file_registration_certificate",
                event.target.files[0]
              );
              setFileRegName(event.target.files[0].name);
            }}
            onBlur={handleBlur}
            ref={fileRef}
            hidden
            accept="image/*"
            type="file"
          />
        </div>

        <div className="my-2 border border-[#a5a5a5] rounded">
          <Button
            className="bg-[#fff] w-full text-[#a5a5a5] justify-between h-14 shadow-none"
            variant="contained"
            component="label"
            endIcon={<DriveFolderUploadIcon />}
            onClick={() => {
              logoRef.current.click();
            }}
            sx={{
              "& .MuiButton-endIcon": {
                color: "black",
              },
              "& .MuiButton-endIcon>*:nth-of-type(1)": {
                fontSize: "30px",
              },
            }}
          >
            {fileLogoName ? (
              <p className="text-[#1976d2]">{fileLogoName} </p>
            ) : (
              "Upload Facility Logo"
            )}
          </Button>
          <input
            id="file_facility_logo"
            name="file_facility_logo"
            onChange={(event) => {
              setFieldValue("file_facility_logo", event.target.files[0]);
              setFileLogoName(event.target.files[0].name);
            }}
            onBlur={handleBlur}
            ref={logoRef}
            hidden
            accept="image/*"
            type="file"
          />
        </div>

        <AppButton buttonText={"Submit"} />
      </form>
    </>
  );
};
