import axios from "axios";
export const loginRequest = async (email, password) => {
  const config = {
    method: "post",
    url: `/rxone/login/staff`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ email, password }),
  };

  try {
    const response = await axios(config);
    const { data } = response;
    if (data && data.access_token && data.is_sales) {
      localStorage.setItem("userData", JSON.stringify(response.data));
    }
    return data;
  } catch (error) {
    throw new Error(error);
  }
};
