import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import DoneOutlineOutlinedIcon from "@mui/icons-material/DoneOutlineOutlined";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { apiRequest } from "../helpers/apiRequest";
import { Card } from "@mui/material";
import moment from "moment";
import Loader from "../components/Loader";

export default function UserStatus({ setLayout }) {
  const [userData, setUserData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const fetchData = async () => {
    setLoading(true);
    apiRequest(`/rxone/user/onboarding/card/${process.env.MODE}/${id}`, "get")
      .then((response) => {
        setUserData(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    setLayout(false);
    fetchData();
  }, []);
  return (
    <>
      <div
        className="h-16 flex items-center border-b-2 border-[#a5a5a5]"
        onClick={() => navigate(-1)}
      >
        <div className="pl-5 flex items-center">
          <ArrowBackIcon />
          <p className="pl-1 ">{" Back"}</p>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        userData && (
          <div className="m-3">
            <div className="flex justify-between">
              <p className="text-xl">{userData.full_name}</p>
              <div className="text-xs">
                <div className="flex justify-end items-center">
                  <LocalPhoneIcon className="text-sm" />
                  <p>{userData.contact_number}</p>
                </div>
                <div className="flex justify-end items-center">
                  <EmailIcon className="text-sm" />
                  <p>{userData.contact_email}</p>
                </div>
              </div>
            </div>
            <div className="flex justify-between my-3.5">
              <p className="text-xs">
                Registered On:
                {moment(userData.registered_on).format("Do MMM YYYY")}
              </p>
              <p className="text-xs">
                Confirmed On:{" "}
                {moment(userData.registered_on).format("Do MMM YYYY")}
              </p>
            </div>

            <Card className="bg-[#007382] text-white p-3.5 font-medium flex justify-between mb-2 mt-16">
              <p>1. Email Verified</p>
              {userData.email_confirmed ? (
                <DoneOutlineOutlinedIcon />
              ) : (
                <ReportProblemOutlinedIcon className="text-orange-500" />
              )}
            </Card>
            <Card className="bg-[#007382] text-white p-3.5 font-medium flex justify-between my-2">
              <p>2. KYC Completion </p>
              {userData.kyc_status === "Completed" ? (
                <DoneOutlineOutlinedIcon />
              ) : (
                <div className="flex">
                  <p>50% &nbsp;</p>
                  <ReportProblemOutlinedIcon className="text-orange-500" />
                </div>
              )}
            </Card>
            <Card className="bg-[#007382] text-white p-3.5 font-medium flex justify-between my-2">
              <p>3. Facility Approval</p>
              {userData.facility_approval === "Completed" ? (
                <DoneOutlineOutlinedIcon />
              ) : (
                <>
                  <ReportProblemOutlinedIcon className="text-orange-500" />
                </>
              )}
            </Card>
            <Card className="bg-[#007382] text-white p-3.5 font-medium flex justify-between my-2">
              <p>4. Doctor Confirmation</p>
              {userData.doctor_confirmation === "Completed" ? (
                <DoneOutlineOutlinedIcon />
              ) : (
                <>
                  <ReportProblemOutlinedIcon className="text-orange-500" />
                </>
              )}
            </Card>
          </div>
        )
      )}
    </>
  );
}
