export const isLoggedin = () => {
  let userData = localStorage.getItem("userData");
  if (userData) {
    userData = JSON.parse(userData);
    if (userData.access_token && userData.is_sales) return true;
  }
  return false;
};

export const logout = () => {
  localStorage.removeItem("userData");
};
