import axios from "axios";

export const addNewDoctor = async (hospital_id, data) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (data && data.phone) {
    data.phone = "+91" + data.phone;
  }
  const config = {
    method: "post",
    url: `/rxone/facility/register/doc/${process.env.MODE}/${hospital_id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
    data: JSON.stringify(data),
  };

  try {
    const response = await axios(config);
    if (response && response.data) return response.data;
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const createDoctorSchedule = async (
  hospital_id,
  docId,
  data,
  online = false
) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (online) data.appointment_type = "online";
  else data.appointment_type = "in-person";
  const config = {
    method: "put",
    url: `/rxone/doctor/recurr/schedule/${process.env.MODE}/${hospital_id}/${docId}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
    data: JSON.stringify(data),
  };

  try {
    const response = await axios(config);
    if (response && response.data) return response.data;
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const uploadDoctorDocuments = async (
  hos_id,
  doc_id,
  accredBy,
  formdata
) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const config = {
    method: "put",
    url: `/rxone/facility/doctor/submit/documents/${process.env.MODE}/${hos_id}/${doc_id}/${accredBy}`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${userData.access_token}`,
    },
    data: formdata,
  };

  try {
    const response = await axios(config);
    if (response && response.data) return response.data;
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
