import axios from "axios";
import * as _ from "lodash";
import { logout } from "./utils";

export const apiRequest = async (
  endpoint,
  method,
  body = {},
  queryParam = {},
  headers = null
) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const config = {
    method,
    url: axios.defaults.baseURL + endpoint,
    headers: headers || {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
    data: JSON.stringify(body),
  };

  console.log(`config :: `, config);

  try {
    const response = await axios(config);
    if (response && response.data) return response.data;
    return response;
  } catch (error) {
    const reason = _.get(error, "response.data.error", null);
    if (reason === "token_expired") {
      logout();
      throw new Error("token_expired");
    }
    const message = _.get(error, "response.data.message", null);
    if (message) throw new Error(message);
    throw new Error(error);
  }
};
