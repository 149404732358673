import React, { useState } from "react";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import QuizIcon from "@mui/icons-material/Quiz";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import LogoutIcon from "@mui/icons-material/Logout";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import moment from "moment";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { useNavigate } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import Logo from "../favicon.png";

export default function Header() {
  const navigate = useNavigate();

  const logout = async () => {
    await localStorage.removeItem("userData");
    await navigate("/login");
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const user = JSON.parse(localStorage.getItem("userData"));

  const topDrawerList = [
    {
      displayName: "Marketing Material",
      prefixIcon: <QueryStatsIcon />,
    },
    {
      displayName: "FAQ",
      prefixIcon: <QuizIcon />,
    },
    {
      displayName: "Contact Us",
      prefixIcon: <ContactPageIcon />,
    },
    {
      displayName: "Sign Out",
      prefixIcon: <LogoutIcon />,
      onClick: handleClickOpen,
    },
  ];
  const [isDrawerOpen, setDrawer] = useState(false);

  const toggleDrawer = () => {
    setDrawer(!isDrawerOpen);
  };

  return (
    <>
      <div className="flex justify-between items-center p-3">
        <div className="flex items-center">
          <AccountCircleOutlinedIcon
            className="text-5xl"
            onClick={toggleDrawer}
          />
          <div className="pl-3">
            <p className="text-base">
              {user && user.first_name ? user.first_name : ""}
              {user && user.last_name ? " " + user.last_name : ""}
            </p>
            <p className="text-xs">Your Location</p>
            <p className="text-sm">Sector 52</p>
          </div>
        </div>

        <NotificationsOutlinedIcon className="text-5xl text-[#ed7d31]" />
      </div>
      <Divider />
      <div className="flex items-center p-3 bg-[#BFBFBF]">
        Today, {user && moment().format("Do MMM YYYY")}
      </div>
      <div>
        <Drawer anchor={"left"} open={isDrawerOpen}>
          <Box
            sx={{
              width: "100vw",
            }}
            role="presentation"
          >
            <ListItem key={"text"} disablePadding>
              <ListItemButton>
                <ListItemIcon
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: "40px",
                    },
                  }}
                >
                  <AccountCircleOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    (user && user.first_name ? user.first_name : "") +
                    (user && user.last_name ? " " + user.last_name : "")
                  }
                />
                <ArrowForwardIosIcon onClick={toggleDrawer} />
              </ListItemButton>
            </ListItem>

            <Divider />
            <List>
              {topDrawerList.map((item) => (
                <ListItem key={item.displayName} disablePadding>
                  <ListItemButton
                    onClick={() => {
                      if (item.onClick) {
                        item.onClick();
                      }
                    }}
                  >
                    <ListItemIcon>{item.prefixIcon}</ListItemIcon>
                    <ListItemText primary={item.displayName} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider />
            <List>
              <ListItem key={"about-version"} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    {/* <InboxIcon /> */}
                    <img
                      src={Logo}
                      alt="logo"
                      style={{ width: "1.5rem", height: "1.5rem" }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={"About RxOne | V2.0"} />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Drawer>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to logout?"}
        </DialogTitle>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={logout} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
