import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import KYC from "./pages/KYC";
import Login from "./pages/Login";
import Facilities from "./pages/Facilities";
import Onboard from "./pages/Onboard";
import Header from "./components/Header";
import BottomNavbar from "./components/BottomNavbar";
import RegisterUser from "./pages/RegisterUser";
import KycForm from "./pages/KycForm";
import RegisterFacility from "./pages/RegisterFacility";
import UserStatus from "./pages/UserStatus";
import Doctors from "./pages/Doctors";
import RegisterDoctor from "./pages/RegisterDoctor";

function App() {
  const [showLayout, setLayout] = useState(true);

  return (
    <div className="max-w-xl my-0 mx-auto">
      <BrowserRouter>
        {/* TODO: bad way to hide parent component */}
        {showLayout && <Header />}
        <Routes>
          <Route path="/" element={<Dashboard setLayout={setLayout} />} />
          <Route path="onboard">
            <Route index element={<Onboard setLayout={setLayout} />} />
            <Route
              path="add"
              element={<RegisterUser setLayout={setLayout} />}
            />
            <Route
              path="user/:id"
              element={<UserStatus setLayout={setLayout} />}
            />
          </Route>
          <Route path="kyc">
            <Route index element={<KYC setLayout={setLayout} />} />
            <Route
              path="verification"
              element={<KycForm setLayout={setLayout} />}
            />
          </Route>
          <Route path="facilities">
            <Route index element={<Facilities setLayout={setLayout} />} />
            <Route
              path="add/:userid"
              element={<RegisterFacility setLayout={setLayout} />}
            />
          </Route>
          <Route path="doctors">
            <Route index element={<Doctors setLayout={setLayout} />} />
            <Route
              path="add/:hospital_id"
              element={<RegisterDoctor setLayout={setLayout} />}
            />
          </Route>
          <Route path="login" element={<Login setLayout={setLayout} />} />
        </Routes>
        {showLayout && <BottomNavbar />}
      </BrowserRouter>
    </div>
  );
}

export default App;
