import React, { useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Icon } from "@iconify/react";
import { motion } from "framer-motion";
import { loginRequest } from "../apis/access";

let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};

const LoginForm = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [emailLabel, setEmailLabel] = useState("Email");
  const [passwordLabel, setPasswordLabel] = useState("Password");
  const [passwordError, setPasswordError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async () => {
    if (email === "") {
      setEmailError(true);
      return;
    }
    if (password === "") {
      setPasswordError(true);
      return;
    }

    try {
      const response = await loginRequest(email, password);
      if (response && response.access_token && response.is_sales) {
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      component={motion.div}
      animate={{
        transition: {
          staggerChildren: 0.55,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
        component={motion.div}
        initial={{ opacity: 0, y: 40 }}
        animate={animate}
      >
        <TextField
          focused={false}
          fullWidth
          autoComplete="username"
          value={email}
          className="bg-[#fff] outline-4 outline-[#a5a5a5]"
          type="email"
          error={emailError}
          onChange={(e) => {
            setEmail(e.target.value);
            setEmailError(false);
            if (e.target.value.length !== 0) {
              setEmailLabel("");
            } else {
              setEmailLabel("Email");
            }
          }}
          label={emailLabel}
        />

        <TextField
          focused={false}
          fullWidth
          className="bg-[#fff]"
          autoComplete="current-password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setPasswordError(false);
            if (e.target.value.length !== 0) {
              setPasswordLabel("");
            } else {
              setPasswordLabel("Password");
            }
          }}
          type={showPassword ? "text" : "password"}
          label={passwordLabel}
          error={passwordError}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                  {showPassword ? (
                    <Icon icon="eva:eye-fill" />
                  ) : (
                    <Icon icon="eva:eye-off-fill" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box
        component={motion.div}
        initial={{ opacity: 0, y: 20 }}
        animate={animate}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <Link
            component={RouterLink}
            variant="subtitle2"
            to="#"
            underline="hover"
            className="text-[#00b0f0]"
          >
            Forgot password?
          </Link>
        </Stack>
        <div className="flex justify-end">
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            onClick={handleSubmit}
            variant="contained"
            className="w-3/6 text-[#007084] bg-[#fff]"
          >
            Login Now
          </LoadingButton>
        </div>
      </Box>
    </Box>
  );
};

export default LoginForm;
