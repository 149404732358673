import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Step1 from "./doctor-forms/Step1";
import Step2 from "./doctor-forms/Step2";
import Step3 from "./doctor-forms/Step3";
import Step4 from "./doctor-forms/Step4";
import OverlayLoader from "../components/OverlayLoader";

const steps = [
  "Doctor’s Details",
  "Online Schedule",
  "In-Person Schedule",
  "Upload Documents & Submit",
];

export default function RegisterDoctor({ setLayout }) {
  const navigate = useNavigate();
  const { hospital_id } = useParams();

  const [activeStep, setActiveStep] = React.useState(0);
  const [isLoading, setLoading] = React.useState(false);
  const [skipped, setSkipped] = React.useState(new Set());
  const [accredBy, setAccredBy] = React.useState(null);
  const [docId, setDocId] = React.useState(null);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    setLayout(false);
  }, [setLayout]);
  return (
    <>
      {isLoading && <OverlayLoader />}
      <div
        className="h-16 flex items-center border-b-2 border-[#a5a5a5]"
        onClick={() => {
          if (activeStep > 0) handleBack();
          else navigate("/doctors");
        }}
      >
        <div className="pl-5 flex items-center">
          <ArrowBackIcon />
          <p className="pl-1 ">{" Back"}</p>
        </div>
      </div>

      <div className="p-5">
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{
            "& .MuiStepConnector-line": {
              display: "none",
            },
          }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel
                sx={{
                  "& .MuiStepLabel-label": {
                    fontSize: "10px",
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 ? (
          <>
            <Step1
              handleNext={handleNext}
              setAccredBy={setAccredBy}
              hospital_id={hospital_id}
              setLoading={setLoading}
              setDocId={setDocId}
            />
          </>
        ) : activeStep === 1 ? (
          <Step2
            handleNext={handleNext}
            hospital_id={hospital_id}
            setLoading={setLoading}
            docId={docId}
          />
        ) : activeStep === 2 ? (
          <Step3
            handleNext={handleNext}
            hospital_id={hospital_id}
            setLoading={setLoading}
            docId={docId}
          />
        ) : activeStep === 3 ? (
          <Step4
            accredBy={accredBy}
            hospital_id={hospital_id}
            setLoading={setLoading}
            docId={docId}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
