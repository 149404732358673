import React, { useEffect, useState } from "react";
import InputField from "../components/InputField";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as Yup from "yup";
import { apiRequest } from "../helpers/apiRequest";
import AppButton from "../components/AppButton";
import { useFormik } from "formik";
import Loader from "../components/Loader";

const validationSchema = Yup.object({
  email: Yup.string().email().required("Please enter your email"),
  firstname: Yup.string()
    .min(2)
    .max(50)
    .required("Please enter your first name"),
  lastname: Yup.string().min(1).max(50).required("Please enter your last name"),
  phone: Yup.string()
    .min(10)
    .max(10)
    .required("Please enter 10 digit mobile number"),
  password: Yup.string().min(6).required("Please enter your password"),
  category: Yup.string().required("Please enter your category"),
  others: Yup.string().when("category", {
    is: (category) => category === "others",
    then: Yup.string().required("Please specify facility"),
  }),
});

export default function RegisterUser({ setLayout }) {
  const [loading, setLoading] = useState(Boolean);
  const navigate = useNavigate();
  const initialValues = {
    email: "",
    phone: "",
    firstname: "",
    lastname: "",
    password: "",
    category: "",
    others: "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (values) => {
        if (values && values.others) {
          values.category = values.others;
        }
        values.phone = `+91` + values.phone;
        values.terms_agreement = "Yes";
        values.referral_code = "";
        delete values.others;
        setLoading(true);
        try {
          await apiRequest(`/rxone/onboard/user`, "post", values);

          setTimeout(() => {
            navigate(-1);
          }, 300);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      },
    });

  useEffect(() => {
    setLayout(false);
  }, [setLayout]);
  return (
    <>
      {loading && <Loader />}

      <div
        className="h-16 flex items-center border-b-2 border-[#a5a5a5]"
        onClick={() => navigate(-1)}
      >
        <div className="pl-5 flex items-center">
          <ArrowBackIcon />
          <p className="pl-1 ">{" Back"}</p>
        </div>
      </div>

      <div className="p-5">
        <form onSubmit={handleSubmit}>
          <InputField
            id={"email"}
            name={"email"}
            type={"email"}
            placeholder={"Email"}
            value={values.email}
            error={errors.email && touched.email}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.email ? errors.email : touched.email}
          />

          <InputField
            id={"phone"}
            name={"phone"}
            type={"phone"}
            placeholder={"Mobile No"}
            value={values.phone}
            error={errors.phone && touched.phone}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.phone ? errors.phone : touched.phone}
          />

          <div className="flex">
            <div className="mr-1.5">
              <InputField
                id={"firstname"}
                name={"firstname"}
                type={"text"}
                placeholder={"First Name"}
                value={values.firstname}
                error={errors.firstname && touched.firstname}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  touched.firstname ? errors.firstname : touched.firstname
                }
              />
            </div>

            <div>
              <InputField
                id={"lastname"}
                name={"lastname"}
                type={"text"}
                placeholder={"Last Name"}
                value={values.lastname}
                error={errors.lastname && touched.lastname}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  touched.lastname ? errors.lastname : touched.lastname
                }
              />
            </div>
          </div>

          <InputField
            id={"password"}
            name={"password"}
            type={"password"}
            value={values.password}
            placeholder={"Password"}
            error={errors.password && touched.password}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.password ? errors.password : touched.password}
          />

          <InputField
            id={"category"}
            name={"category"}
            type={"options"}
            error={errors.category && touched.category}
            value={values.category}
            onChange={handleChange}
          />

          {values.category === "others" ? (
            <InputField
              id={"others"}
              name={"others"}
              type={"text"}
              placeholder={"Please Specify Facility"}
              value={values.others}
              error={errors.others && touched.others}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.others ? errors.others : touched.others}
            />
          ) : (
            <></>
          )}

          <AppButton buttonText={"Save"} />
        </form>
      </div>
    </>
  );
}
