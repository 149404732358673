import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputField from "../../components/InputField";
import AppButton from "../../components/AppButton";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import ManIcon from "@mui/icons-material/Man";
import WomanIcon from "@mui/icons-material/Woman";
import TransgenderIcon from "@mui/icons-material/Transgender";
import { addNewDoctor } from "../../apis/doctors";

const validationSchema = Yup.object({
  email: Yup.string().email().required("Please enter your email"),
  firstname: Yup.string()
    .min(2)
    .max(50)
    .required("Please enter your first name"),
  lastname: Yup.string().min(1).max(50).required("Please enter your lastname"),
  phone: Yup.string().min(10).max(10).required("Please enter your phone"),
  gender: Yup.string().min(1).max(50).required("Please enter your gender"),
  qualification: Yup.string()
    .min(1)
    .max(100)
    .required("Please enter your qualification"),
  speciality: Yup.string()
    .min(1)
    .max(100)
    .required("Please enter your speciality"),
  rmp_accred_by: Yup.string()
    .min(1)
    .max(100)
    .required("Please enter your rmp_accred_by"),
  rmp_reg_year: Yup.number().required("Please enter your rmp_reg_year"),
  rmp_birth_year: Yup.number().required("Please enter your rmp_birth_year"),
  rmp_degree_year: Yup.number().required("Please enter your rmp_degree_year"),
  rmp_num: Yup.number().required("Please enter your rmp_num"),
  consult_charge: Yup.number().required("Please enter your consult_charge"),
  online_discount: Yup.number().required("Please enter your online_discount"),
});

export default function Step1(props) {
  const initialValues = {
    firstname: "",
    lastname: "",
    gender: "",
    email: "",
    phone: "",
    qualification: "",
    speciality: "",
    rmp_accred_by: "",
    rmp_reg_year: "",
    rmp_birth_year: "",
    rmp_degree_year: "",
    rmp_num: "",
    consult_charge: "",
    online_discount: "",
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        props.setLoading(true);
        const response = await addNewDoctor(props.hospital_id, values);
        if (response && response.doc_id) {
          props.setDocId(response.doc_id);
          props.setAccredBy(values.rmp_accred_by);
          props.handleNext();
        }
      } catch (error) {
        console.log(error);
      } finally {
        props.setLoading(false);
      }
    },
  });
  return (
    <>
      {}
      <form onSubmit={handleSubmit}>
        <InputField
          id={"firstname"}
          name={"firstname"}
          type={"text"}
          placeholder={"First Name"}
          value={values.firstname}
          error={errors.firstname && touched.firstname}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <InputField
          id={"lastname"}
          name={"lastname"}
          type={"text"}
          placeholder={"Last Name"}
          value={values.lastname}
          error={errors.lastname && touched.lastname}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <RadioGroup
          className="my-2"
          row
          name="gender"
          id={"gender"}
          value={values.gender}
          error={errors.gender && touched.gender}
          onChange={() => {
            console.log(`changed`);
          }}
          sx={{
            "& .MuiFormControlLabel-root": {
              marginLeft: 0,
            },
          }}
        >
          <FormControlLabel
            value="female"
            control={
              <Button
                className={values.gender === "female" ? "bg-[#1976d2]" : ""}
                variant={values.gender === "female" ? "contained" : "outlined"}
                startIcon={<WomanIcon />}
                onClick={() => {
                  setFieldValue("gender", "female");
                }}
              >
                Female
              </Button>
            }
          />
          <FormControlLabel
            className={values.gender === "male" ? "bg-[#1976d2]" : ""}
            value="male"
            control={
              <Button
                variant={values.gender === "male" ? "contained" : "outlined"}
                startIcon={<ManIcon />}
                onClick={() => {
                  setFieldValue("gender", "male");
                }}
              >
                Male
              </Button>
            }
          />
          <FormControlLabel
            className={values.gender === "other" ? "bg-[#1976d2]" : ""}
            value="other"
            control={
              <Button
                variant={values.gender === "other" ? "contained" : "outlined"}
                startIcon={<TransgenderIcon />}
                onClick={() => {
                  setFieldValue("gender", "other");
                }}
              >
                Other
              </Button>
            }
          />
        </RadioGroup>

        <InputField
          id={"email"}
          name={"email"}
          type={"text"}
          placeholder={"Email"}
          value={values.email}
          error={errors.email && touched.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <InputField
          id={"phone"}
          name={"phone"}
          type={"mobileno"}
          placeholder={"Mobile No"}
          value={values.phone}
          error={errors.phone && touched.phone}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <InputField
          id={"qualification"}
          name={"qualification"}
          type={"text"}
          placeholder={"Qualification"}
          value={values.qualification}
          error={errors.qualification && touched.qualification}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <InputField
          id={"speciality"}
          name={"speciality"}
          type={"text"}
          placeholder={"Speciality"}
          value={values.speciality}
          error={errors.speciality && touched.speciality}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <InputField
          id={"rmp_accred_by"}
          name={"rmp_accred_by"}
          type={"text"}
          placeholder={"RMP Accred By"}
          value={values.rmp_accred_by}
          error={errors.rmp_accred_by && touched.rmp_accred_by}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <InputField
          id={"rmp_reg_year"}
          name={"rmp_reg_year"}
          type={"text"}
          placeholder={"RMP Registration Year"}
          value={values.rmp_reg_year}
          error={errors.rmp_reg_year && touched.rmp_reg_year}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <InputField
          id={"rmp_birth_year"}
          name={"rmp_birth_year"}
          type={"text"}
          placeholder={"RMP Birth Year"}
          value={values.rmp_birth_year}
          error={errors.rmp_birth_year && touched.rmp_birth_year}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <InputField
          id={"rmp_degree_year"}
          name={"rmp_degree_year"}
          type={"text"}
          placeholder={"RMP Degree Year"}
          value={values.rmp_degree_year}
          error={errors.rmp_degree_year && touched.rmp_degree_year}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <InputField
          id={"rmp_num"}
          name={"rmp_num"}
          type={"text"}
          placeholder={"RMP Number"}
          value={values.rmp_num}
          error={errors.rmp_num && touched.rmp_num}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <InputField
          id={"consult_charge"}
          name={"consult_charge"}
          type={"text"}
          placeholder={"Consult Charge"}
          value={values.consult_charge}
          error={errors.consult_charge && touched.consult_charge}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <InputField
          id={"online_discount"}
          name={"online_discount"}
          type={"text"}
          placeholder={"Online Discount"}
          value={values.online_discount}
          error={errors.online_discount && touched.online_discount}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <AppButton buttonText={"Save & Next"} />
      </form>
    </>
  );
}
