import { TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

export default function InputField(props) {
  const {
    type,
    value,
    placeholder,
    name,
    id,
    error,
    onChange,
    onBlur,
    suffixIcon,
    helperText,
  } = props;
  return type === "options" ? (
    <Select
      value={value}
      onChange={onChange}
      name={name}
      id={id}
      error={error}
      className={`bg-[#fff] outline-4 outline-[#a5a5a5] my-2 w-full ${
        value ? "" : "text-[#a5a5a5]"
      }`}
      displayEmpty
      inputProps={{ "aria-label": "Without label" }}
    >
      <MenuItem value="" disabled>
        <em>Category</em>
      </MenuItem>
      <MenuItem value={"medical_facility"}>
        Medical Facility (Clinic/Hospital)
      </MenuItem>
      <MenuItem value={"doctor"}>Doctor/Medical Professional</MenuItem>
      <MenuItem value={"pharmacy"}>Pharmacy</MenuItem>
      <MenuItem value={"test_lab"}>Test Lab</MenuItem>
      <MenuItem value={"others"}>Others</MenuItem>
    </Select>
  ) : type === "mobileno" ? (
    <TextField
      // focused
      fullWidth
      className="bg-[#fff] outline-4 outline-[#a5a5a5] my-2"
      label={""}
      type={type}
      value={value}
      helperText={error && helperText}
      placeholder={placeholder}
      name={name}
      id={id}
      error={!!error}
      onChange={onChange}
      onBlur={onBlur}
      InputProps={{
        startAdornment: <InputAdornment position="start">+91</InputAdornment>,
      }}
    />
  ) : type === "file" ? (
    <div className="my-2 border border-[#a5a5a5] rounded">
      <Button
        className="bg-[#fff] w-full text-[#a5a5a5] justify-between h-14 shadow-none"
        variant="contained"
        component="label"
        endIcon={suffixIcon}
        sx={{
          "& .MuiButton-endIcon": {
            color: "black",
          },
          "& .MuiButton-endIcon>*:nth-of-type(1)": {
            fontSize: "30px",
          },
        }}
      >
        {placeholder}
        <input hidden accept="image/*" multiple type="file" />
      </Button>
      {/* <IconButton color="primary" aria-label="upload picture" component="label">
        <input hidden accept="image/*" type="file" />
        {suffixIcon}
      </IconButton> */}
    </div>
  ) : (
    // <TextField
    //   // focused
    //   fullWidth
    //   className="bg-[#fff] outline-4 outline-[#a5a5a5] my-2"
    //   label={""}
    //   type={type}
    //   value={value}
    //   placeholder={placeholder}
    //   name={name}
    //   id={id}
    //   error={!!error}
    //   onChange={onChange}
    //   onBlur={onBlur}
    //   InputProps={{
    //     endAdornment: (
    //       <InputAdornment position="end">{suffixIcon}</InputAdornment>
    //     ),
    //   }}
    // />
    <TextField
      // focused
      fullWidth
      className="bg-[#fff] outline-4 outline-[#a5a5a5] my-2"
      label={""}
      type={type}
      value={value}
      placeholder={placeholder}
      name={name}
      id={id}
      helperText={error && helperText}
      error={!!error}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
}
