import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PlaceIcon from "@mui/icons-material/Place";
import { apiRequest } from "../helpers/apiRequest";
import { isLoggedin } from "../helpers/utils";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Loader from "../components/Loader";

const KYC = ({ setLayout }) => {
  const navigate = useNavigate();
  const [onboardedUsers, setOnboardedUsers] = useState([]);
  const [isLoading, setLoading] = useState(Boolean);

  const fetchData = async () => {
    apiRequest("/rxone/list/users/kyc", "get")
      .then((response) => {
        setOnboardedUsers(response.users);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (!isLoggedin) {
          navigate("/login");
        }
        console.log(error);
      });
  };

  useEffect(() => {
    setLayout(true);
    if (!isLoggedin()) {
      navigate("/login");
    }
    setLoading(true);
    fetchData();
  }, []);
  return (
    <>
      <p className="text-xs mt-2 mb-4">
        Click on User Card to see the current status of user
      </p>
      {isLoading ? (
        <Loader />
      ) : onboardedUsers && onboardedUsers.length ? (
        onboardedUsers.map((user) => {
          return (
            <Card className="m-2" key={user.user_id}>
              <div className="flex">
                <div className="flex flex-col text-center justify-center items-center">
                  <PlaceIcon />
                  <div className="min-w-[100px]">{user.category}</div>
                </div>
                <div className="flex flex-col text-sm">
                  <p className="text-base">{user.full_name}</p>
                  <div className="flex">
                    <LocalPhoneIcon />
                    <p>{user.phone}</p>
                  </div>
                  <div className="flex">
                    <EmailIcon />
                    <p>{user.email}</p>
                  </div>
                  <p>
                    Registered On:
                    {user &&
                      user.registered_on &&
                      moment(user.registered_on).format("DD MMM YYYY")}
                  </p>
                  <p>
                    Email Confirmation:
                    <span
                      className={`${
                        user.kyc_status === "Completed"
                          ? "text-lime-500"
                          : "text-orange-500"
                      }`}
                    >
                      {user.kyc_status}
                    </span>
                  </p>
                </div>
              </div>
            </Card>
          );
        })
      ) : (
        "No Onboarded Users"
      )}
    </>
  );
};

export default KYC;
