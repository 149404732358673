import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import AppButton from "../../components/AppButton";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import Button from "@mui/material/Button";
import FormData from "form-data";
import { uploadDoctorDocuments } from "../../apis/doctors";
const formdata = new FormData();

const Step4 = ({ accredBy, hospital_id, setLoading, docId }) => {
  const navigate = useNavigate();
  const fileRef = useRef(null);
  const logoRef = useRef(null);
  const [fileRegName, setFileRegName] = React.useState("");
  const [profilePicture, setProfilePicture] = React.useState("");
  const initialValues = {
    file_registration_certificate: null,
    file_profile_picture: null,
  };

  const validationSchema = Yup.object({
    file_registration_certificate: Yup.mixed()
      .nullable()
      .required()
      .test("FILE_SIZE", "Upload File is too large", (value) => {
        return !value || (value && value.size <= 1024 * 1024);
      }),
    file_profile_picture: Yup.mixed()
      .nullable()
      .required()
      .test("FILE_SIZE", "Upload File is too large", (value) => {
        return !value || (value && value.size <= 1024 * 1024);
      }),
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      formdata.append(
        "registration_certificate",
        values.file_registration_certificate,
        values.file_registration_certificate.name
      );
      formdata.append(
        "brand_logo",
        values.file_profile_picture,
        values.file_profile_picture.name
      );
      setLoading(true);
      try {
        const response = await uploadDoctorDocuments(
          hospital_id,
          docId,
          accredBy,
          formdata
        );

        navigate(`/doctors`);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
  });
  return (
    <form onSubmit={handleSubmit}>
      <div className="my-2 border border-[#a5a5a5] rounded">
        <Button
          className="bg-[#fff] w-full text-[#a5a5a5] justify-between h-14 shadow-none"
          variant="contained"
          component="label"
          endIcon={<DriveFolderUploadIcon />}
          onClick={() => {
            logoRef.current.click();
          }}
          sx={{
            "& .MuiButton-endIcon": {
              color: "black",
            },
            "& .MuiButton-endIcon>*:nth-of-type(1)": {
              fontSize: "30px",
            },
          }}
        >
          {profilePicture ? (
            <p className="text-[#1976d2]">{profilePicture} </p>
          ) : (
            "Upload Profile Picture"
          )}
        </Button>
        <input
          id="file_profile_picture"
          name="file_profile_picture"
          onChange={(event) => {
            setFieldValue("file_profile_picture", event.target.files[0]);
            setProfilePicture(event.target.files[0].name);
          }}
          onBlur={handleBlur}
          ref={logoRef}
          hidden
          accept="image/*"
          type="file"
        />
      </div>

      <div className="my-2 border border-[#a5a5a5] rounded">
        <Button
          className="bg-[#fff] w-full text-[#a5a5a5] justify-between h-14 shadow-none"
          variant="contained"
          component="label"
          endIcon={<CameraAltIcon />}
          onClick={() => {
            fileRef.current.click();
          }}
          sx={{
            "& .MuiButton-endIcon": {
              color: "black",
            },
            "& .MuiButton-endIcon>*:nth-of-type(1)": {
              fontSize: "30px",
            },
          }}
        >
          {fileRegName ? (
            <p className="text-[#1976d2]">{fileRegName} </p>
          ) : (
            "Upload Registration Certificate"
          )}
        </Button>
        <input
          id="file_registration_certificate"
          name="file_registration_certificate"
          onChange={(event) => {
            setFieldValue(
              "file_registration_certificate",
              event.target.files[0]
            );
            setFileRegName(event.target.files[0].name);
          }}
          onBlur={handleBlur}
          ref={fileRef}
          hidden
          accept="image/*"
          type="file"
        />
      </div>

      <AppButton buttonText={"Submit"} />
    </form>
  );
};

export default Step4;

// hospital_id ::  3c1be81f-ccc9-46ba-9ed1-8b938eb8
// docId ::  4c03de3c-015c-479d-aaa5-efca3b91
// accredBy ::  ABCD
