import axios from "axios";

export const uploadFacilityDocument = async (hos_id, formdata) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const config = {
    method: "put",
    url: `/rxone/facility/documents/${process.env.MODE}/${hos_id}`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${userData.access_token}`,
    },
    data: formdata,
  };

  try {
    const response = await axios(config);
    if (response && response.data) return response.data;
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
