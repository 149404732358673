import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PlaceIcon from "@mui/icons-material/Place";
import VerifiedIcon from "@mui/icons-material/Verified";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { isLoggedin } from "../helpers/utils";
import { apiRequest } from "../helpers/apiRequest";
import MenuItem from "@mui/material/MenuItem";
import PinDropRoundedIcon from "@mui/icons-material/PinDropRounded";
import Select from "@mui/material/Select";
import Avatar from "@mui/material/Avatar";
import OverlayLoader from "../components/OverlayLoader";

const Facilities = ({ setLayout }) => {
  const [isLoading, setLoading] = useState(Boolean);
  const [users, setUsers] = useState([]);
  const [selectUserId, setSelectedUserId] = useState("");
  const [facilities, setFacilities] = useState([]);
  const navigate = useNavigate();

  const fetchData = () => {
    setLoading(true);
    apiRequest("/rxone/list/users/kyc", "get")
      .then((response) => {
        setUsers(response.users);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const listFacilities = (userId) => {
    setLoading(true);
    apiRequest(
      `/rxone/list/registered/facilities/${process.env.MODE}/${userId}`,
      "get"
    )
      .then((response) => {
        setFacilities(response.facilities);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLayout(true);
    if (!isLoggedin()) {
      navigate("/login");
    }
    fetchData();
  }, []);
  return (
    <>
      {isLoading && <OverlayLoader />}
      <div className="m-3">
        <p className="text-xs mt-2 mb-4">
          Select approved user from drop down to view registered facilities or
          add new facility
        </p>

        <Select
          className="w-full mb-10"
          value={selectUserId}
          onChange={(event) => {
            setSelectedUserId(event.target.value);
            listFacilities(event.target.value);
          }}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          {users && users.length && (
            <MenuItem value="" disabled>
              <em>Select User</em>
            </MenuItem>
          )}

          {users &&
            users.length &&
            users.map((user) => {
              return (
                <MenuItem key={user.user_id} value={user.user_id}>
                  {user.full_name}
                </MenuItem>
              );
            })}
        </Select>

        {facilities && facilities.length ? (
          facilities.map((facility) => {
            return (
              <Card
                key={facility.hos_id}
                className="flex justify-between items-center p-2 my-2"
                // onClick={() => {
                //   if (!facility.registration_approved)
                //     navigate(
                //       `/facilities/edit/${selectUserId}?facility_id=${facility.hos_id}`
                //     );
                // }}
              >
                <Avatar
                  alt={facility.facility_name}
                  src={`https://rx-one-dev-iroek.ondigitalocean.app/rxone/fetch/facility/logo/${process.env.MODE}/${facility.hos_id}`}
                />
                <div className="flex flex-col">
                  <p className="text-base">{facility.facility_name}</p>
                  <p className="flex text-xs">
                    <PinDropRoundedIcon />
                    Clinic Address
                  </p>
                </div>
                {facility.registration_approved ? (
                  <VerifiedIcon className="text-lime-500" />
                ) : (
                  <WarningAmberRoundedIcon className="text-orange-500" />
                )}
              </Card>
            );
          })
        ) : (
          <>No Registered facilities</>
        )}

        <div>
          <Button
            className="w-full my-5 bg-[#4472c4]"
            variant="contained"
            disabled={!selectUserId}
            onClick={(e) => {
              navigate(`/facilities/add/${selectUserId}`);
            }}
          >
            Add New +
          </Button>
        </div>
      </div>
    </>
  );
};

export default Facilities;
