import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import VerifiedIcon from "@mui/icons-material/Verified";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { isLoggedin } from "../helpers/utils";
import { apiRequest } from "../helpers/apiRequest";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Avatar from "@mui/material/Avatar";
import OverlayLoader from "../components/OverlayLoader";

const Doctors = ({ setLayout }) => {
  const [isLoading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectUserId, setSelectedUserId] = useState("");
  const [selectedFacilityId, setSelectedFacilityId] = useState("");
  const [facilities, setFacilities] = useState([]);
  const [registeredDoctors, setRegisteredDoctors] = useState([]);
  const navigate = useNavigate();

  const fetchData = () => {
    setLoading(true);
    apiRequest("/rxone/list/users/kyc", "get")
      .then((response) => {
        setUsers(response.users);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const listFacilities = (userId) => {
    setLoading(true);
    apiRequest(
      `/rxone/list/registered/facilities/${process.env.MODE}/${userId}`,
      "get"
    )
      .then((response) => {
        console.log(`response :: `, response);
        if (response && response.facilities) setFacilities(response.facilities);
        else {
          setFacilities([]);
          setSelectedFacilityId("");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const listDoctors = (hos_id) => {
    setLoading(true);
    apiRequest(
      `/rxone/facility/list/doctors/${process.env.MODE}/${hos_id}`,
      "get"
    )
      .then((response) => {
        console.log(`response :: `, response);
        if (response && response.registered_doctors)
          setRegisteredDoctors(response.registered_doctors);
        else {
          setRegisteredDoctors([]);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLayout(true);
    if (!isLoggedin()) {
      navigate("/login");
    }
    fetchData();
  }, []);
  return (
    <>
      {isLoading && <OverlayLoader />}
      <div className="m-3">
        <p className="text-xs mt-2 mb-4">
          Select approved facility from drop to view registered doctors with
          facility or click Add New to register new doctor
        </p>

        <Select
          className="w-full mb-10"
          value={selectUserId}
          onChange={(event) => {
            setSelectedUserId(event.target.value);
            listFacilities(event.target.value);
            setSelectedFacilityId("");
            setRegisteredDoctors([]);
          }}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          {users && users.length && (
            <MenuItem value="" disabled>
              <em>Select User</em>
            </MenuItem>
          )}

          {users &&
            users.length &&
            users.map((user) => {
              return (
                <MenuItem key={user.user_id} value={user.user_id}>
                  {user.full_name}
                </MenuItem>
              );
            })}
        </Select>

        <Select
          className="w-full mb-10"
          value={selectedFacilityId}
          onChange={(event) => {
            if (event && event.target && event.target.value) {
              setSelectedFacilityId(event.target.value);
              listDoctors(event.target.value);
              setRegisteredDoctors([]);
            }
          }}
          disabled={!selectUserId}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          {facilities && facilities.length && (
            <MenuItem value="" disabled>
              <em>Select Facility</em>
            </MenuItem>
          )}

          {facilities &&
            facilities.length &&
            facilities.map((facility) => {
              return (
                <MenuItem key={facility.hos_id} value={facility.hos_id}>
                  {facility.facility_name}
                </MenuItem>
              );
            })}
        </Select>

        {registeredDoctors && registeredDoctors.length ? (
          <>
            <p className="text-xs mt-2 mb-4">
              Click on Doctor Card to View or Edit details
            </p>
            {registeredDoctors.map((doctor) => {
              return (
                <Card
                  key={doctor.doc_id}
                  className="flex justify-between items-center p-2"
                >
                  <Avatar
                    alt={doctor.doctor_name}
                    src={`https://rx-one-dev-iroek.ondigitalocean.app/rxone/fetch/doctor/pic/${doctor.doc_id}`}
                  />
                  <div className="flex flex-col">
                    <p className="text-base">{doctor.doctor_name}</p>
                    <p className="flex text-xs">
                      {doctor?.qualification + ", " + doctor?.speciality}
                    </p>
                    <p className="text-xs">
                      {doctor && doctor.phone ? doctor.phone : ""}
                    </p>
                  </div>
                  {doctor.doctor_details_submitted &&
                  doctor.doctor_documents_submitted &&
                  doctor.inperson_schedule_submitted &&
                  doctor.online_schedule_submitted ? (
                    <VerifiedIcon className="text-lime-500" />
                  ) : (
                    <WarningAmberRoundedIcon className="text-orange-500" />
                  )}
                </Card>
              );
            })}
          </>
        ) : (
          <></>
        )}

        <div>
          <Button
            className="w-full my-5 bg-[#4472c4]"
            variant="contained"
            disabled={!selectedFacilityId}
            onClick={(e) => {
              navigate(`/doctors/add/${selectedFacilityId}`);
            }}
          >
            Add New +
          </Button>
        </div>
      </div>
    </>
  );
};

export default Doctors;
