import React, { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import { Paper, Button, CardActionArea, CardActions } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

export default function Dashboard({ setLayout }) {
  const [currentPage, setCurrentPage] = useState(0);
  
  const items = [
    {
      title: "Lizard",
      description: "Probably the most random thing you have ever seen!",
    },
    {
      title: "Lizard 2",
      description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse in odio fermentum dolor sodales elementum eu at ipsum. Cras ullamcorper erat nec luctus suscipit. Nullam faucibus, ante non varius aliquam, diam tellus fringilla urna, eu auctor nulla diam vitae ligula. Vivamus rutrum odio quis quam facilisis sodales. Suspendisse quis maximus risus. Nulla sodales, odio sed fringilla placerat, turpis leo semper massa, et rhoncus nisi nulla eu lectus. Quisque a mauris ligula. Vestibulum laoreet sit amet eros vitae ullamcorper. Nunc quis posuere dui, non ullamcorper lacus. Aliquam at convallis sapien. Sed mauris ipsum, suscipit eu erat ac, suscipit dapibus dui.
Fusce porttitor gravida lacus. Donec consectetur eu augue ac efficitur. Integer vitae augue libero. Mauris massa lacus, varius ut efficitur at, varius ut nulla. In lacinia sagittis sem, nec posuere urna sollicitudin vel. Nunc quis malesuada nulla. Nam vel faucibus metus, eget cursus leo. Fusce quis feugiat felis, eu vulputate dui`,
    },
  ];
  useEffect(() => {
    setLayout(true);
  }, []);
  return (
    <div className="m-3">
      <Box sx={{ my: 2 }}>
        <Typography gutterBottom variant="body1">
          What’s New
        </Typography>
        <Divider />
      </Box>
      <Carousel
        className={`${currentPage > 0 ? "pl-7" : "pr-7"}`}
        NextIcon={<ArrowForwardIosIcon />}
        PrevIcon={<ArrowBackIosNewIcon />}
        cycleNavigation={false}
        indicators={false}
        navButtonsAlwaysVisible={true}
        navButtonsProps={{
          style: {
            color: "black",
            margin: 0,
            padding: 0,
          },
        }}
        onChange={(now) => {
          setCurrentPage(now);
        }}
      >
        {items.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </Carousel>
      <Box sx={{ mt: 6, mb: 2 }}>
        <Typography gutterBottom variant="body1">
          Sales Charts
        </Typography>
        <Divider />
      </Box>
    </div>
  );
}

function Item({ item }) {
  return (
    <Card>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image="https://animals.sandiegozoo.org/sites/default/files/2016-11/animals_hero_lizards.jpg"
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {item.title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            className="max-h-16 text-ellipsis overflow-hidden"
          >
            {item.description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
}
