import * as React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";
import LocalHospitalOutlinedIcon from "@material-ui/icons/LocalHospitalOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    "&$selected": {
      color: "#ed7d31",
    },
  },
  selected: {},
}));

export default function BottomNavbar() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [value, setValue] = React.useState("/");

  const ref = React.useRef(null);
  React.useEffect(() => {
    ref.current.ownerDocument.body.scrollTop = 0;
  }, [value]);
  return (
    <>
      <Box ref={ref}>
        <Paper
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
          elevation={3}
        >
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              navigate(newValue);
              setValue(newValue);
            }}
          >
            <BottomNavigationAction
              label="Home"
              icon={<HomeOutlinedIcon />}
              value={"/"}
              classes={classes}
            />
            <BottomNavigationAction
              label="Onboard"
              icon={<AccountCircleOutlinedIcon />}
              value={"/onboard"}
              classes={classes}
            />
            <BottomNavigationAction
              label="KYC"
              icon={<VerifiedUserOutlinedIcon />}
              value={"/kyc"}
              classes={classes}
            />
            <BottomNavigationAction
              label="Facilities"
              icon={<LocationOnOutlinedIcon />}
              value={"/facilities"}
              classes={classes}
            />
            <BottomNavigationAction
              label="Doctors"
              icon={<LocalHospitalOutlinedIcon />}
              value={"/doctors"}
              classes={classes}
            />
          </BottomNavigation>
        </Paper>
      </Box>
    </>
  );
}
