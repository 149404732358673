import React from "react";
import Button from "@mui/material/Button";

export default function AppButton(props) {
  const { buttonText } = props;
  return (
    <Button
      type="submit"
      className="w-full my-5 bg-[#4472c4] h-12"
      variant="contained"
      // onClick={(e) => {
      //   navigate(`/onboard/addUser`);
      // }}
    >
      <p className="font-sans text-base font-bold">{buttonText}</p>
    </Button>
  );
}
