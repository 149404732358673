import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputField from "../../components/InputField";
import AppButton from "../../components/AppButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { createDoctorSchedule } from "../../apis/doctors";

const weekdays = [
  {
    label: "Monday",
    value: 1,
    id: "monday",
    name: "monday",
  },
  {
    label: "Tuesday",
    value: 2,
    id: "tuesday",
    name: "tuesday",
  },
  {
    label: "Wednesday",
    value: 3,
    id: "wednesday",
    name: "wednesday",
  },
  {
    label: "Thursday",
    value: 4,
    id: "thursday",
    name: "thursday",
  },
  {
    label: "Friday",
    value: 5,
    id: "friday",
    name: "friday",
  },
  {
    label: "Saturday",
    value: 6,
    id: "saturday",
    name: "saturday",
  },
  {
    label: "Sunday",
    value: 7,
    id: "sunday",
    name: "sunday",
  },
];

const validationSchema = Yup.object({
  slot_duration: Yup.string()
    .min(1)
    .max(100)
    .required("Please enter your slot duration"),
  days_list: Yup.array().min(1, "Please select atleast 1 weekday"),
});

export default function Step3(props) {
  const initialValues = {
    slot_duration: "",
    start_time: moment().format("hh:mm"),
    end_time: moment().format("hh:mm"),
    days_list: [],
    schedule_end_date: dayjs(),
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        props.setLoading(true);
        const response = await createDoctorSchedule(
          props.hospital_id,
          props.docId,
          values
        );
        if (response && response.message) props.handleNext();
      } catch (error) {
        console.log(error);
      } finally {
        props.setLoading(false);
      }
    },
  });
  return (
    <form onSubmit={handleSubmit}>
      <FormLabel component="legend">Weekdays</FormLabel>
      {/* <FormGroup aria-label="position" row id="days_list" name="days_list"> */}
      {weekdays.map((day) => {
        return (
          <FormControlLabel
            value={day.value}
            key={day.name}
            control={
              <Checkbox
                name={"days_list"}
                id={day.id}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            }
            label={day.label}
            labelPlacement="end"
          />
        );
      })}
      {/* </FormGroup> */}
      <div className="flex justify-between my-4">
        <TextField
          className="w-full"
          id="start_time"
          name="start_time"
          label="Start Time"
          type="time"
          value={values.start_time}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <TextField
          className="w-full"
          id="end_time"
          name="end_time"
          label="End Time"
          type="time"
          value={values.end_time}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <InputField
        id={"slot_duration"}
        name={"slot_duration"}
        type={"text"}
        placeholder={"Slot Duration (in minutes)"}
        value={values.slot_duration}
        error={errors.slot_duration && touched.slot_duration}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          id={"schedule_time"}
          name={"schedule_time"}
          className="w-full my-4"
          label="Schedule Till (End Date)"
          inputFormat="YYYY-MM-DD"
          value={values.schedule_end_date}
          onChange={(e) => {
            setFieldValue(
              "schedule_end_date",
              moment(new Date(e)).format("YYYY-MM-DD")
            );
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>

      <AppButton buttonText={"Save & Next"} />
    </form>
  );
}
