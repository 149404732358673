import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Box from "@mui/material/Box";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputField from "../components/InputField";
import AppButton from "../components/AppButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const steps = [
  "Contact info",
  "Bussiness/Ind Deatils",
  "Account Details",
  "Document Submission",
];

const validationSchema = Yup.object({
  email: Yup.string().email().required("Please enter your email"),
  first_name: Yup.string()
    .min(2)
    .max(50)
    .required("Please enter your first_name"),
  last_name: Yup.string()
    .min(1)
    .max(50)
    .required("Please enter your last_name"),
  mobileno: Yup.string().min(10).max(10).required("Please enter your mobileno"),
  password: Yup.string().min(6).required("Please enter your password"),
  category: Yup.string().required("Please enter your category"),
  others: Yup.string().when("category", {
    is: (category) => category === "others",
    then: Yup.string().required("I am required now the checkbox is checked"),
  }),
});

export default function KycForm({ setLayout }) {
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const initialValues = {
    email: "",
    mobileno: "",
    first_name: "",
    last_name: "",
    password: "",
    category: "",
    others: "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (values) => {
        if (values && values.others) {
          values.category = values.others;
        }
        //   values.mobileno = `+91` + values.mobileno;
        //   values.terms_agreement = "Yes";
        //   values.referral_code = "";
        //   delete values.others;

        //   setLoading(true);
        //   try {
        //     const response = await apiRequest(
        //       `/rxone/onboard/user`,
        //       "post",
        //       values
        //     );
        //     console.log(`response :: `, response);
        //     setLoading(false);
        //   } catch (error) {
        //     console.log(`error :: `, error);
        //     setLoading(false);
        //     console.log(error);
        //   }
      },
    });

  useEffect(() => {
    setLayout(false);
  }, [setLayout]);
  return (
    <>
      <div
        className="h-16 flex items-center border-b-2 border-[#a5a5a5]"
        onClick={() => navigate(-1)}
      >
        <div className="pl-5 flex items-center">
          <ArrowBackIcon />
          <p className="pl-1 ">{" Back"}</p>
        </div>
      </div>
      <div className="p-5">
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{
            "& .MuiStepConnector-line": {
              display: "none",
            },
          }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel
                sx={{
                  "& .MuiStepLabel-label": {
                    fontSize: "8px",
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length ? (
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </>
        ) : (
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />

              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box>
          </>
        )}

        {/* <form onSubmit={handleSubmit}>
          <InputField
            id={"email"}
            name={"email"}
            type={"email"}
            placeholder={"Email"}
            value={values.email}
            error={errors.email && touched.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <InputField
            id={"mobileno"}
            name={"mobileno"}
            type={"mobileno"}
            placeholder={"Mobile No"}
            value={values.mobileno}
            error={errors.mobileno && touched.mobileno}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <div className="flex">
            <div className="mr-1.5">
              <InputField
                id={"first_name"}
                name={"first_name"}
                type={"text"}
                placeholder={"First Name"}
                value={values.first_name}
                error={errors.first_name && touched.first_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>

            <div>
              <InputField
                id={"last_name"}
                name={"last_name"}
                type={"text"}
                placeholder={"Last Name"}
                value={values.last_name}
                error={errors.last_name && touched.last_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>

          <InputField
            id={"password"}
            name={"password"}
            type={"password"}
            value={values.password}
            placeholder={"Password"}
            error={errors.password && touched.password}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <InputField
            id={"category"}
            name={"category"}
            type={"options"}
            error={errors.category && touched.category}
            value={values.category}
            onChange={handleChange}
          />

          {values.category === "others" ? (
            <InputField
              id={"others"}
              name={"others"}
              type={"text"}
              placeholder={"Please Specify Facility"}
              value={values.others}
              error={errors.others && touched.others}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          ) : (
            <></>
          )}

          <AppButton buttonText={"Register New +"} />
        </form> */}
      </div>
    </>
  );
}
