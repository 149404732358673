import { combineReducers } from "redux";

// import changeNumber from "./upDown";
import handleLoginData from "./login";

const rootReducer = combineReducers({
  // changeNumber,
  handleLoginData,
  // another change data like type
});

export default rootReducer;
